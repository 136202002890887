<template>
  <jf-layout>
    <template slot="head">
      <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:systemnotice:save')" @click="add">新增</el-button>
      <el-button size="small" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:systemnotice:delete')" :disabled="!selectList.length" @click="remove(null)">删除</el-button>
    </template>

    <el-table slot="body" border :data="list" v-loading="loading" @selection-change="selectChange">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="title" label="标题" />
      <el-table-column align="center" prop="scope" label="推送范围" show-overflow-tooltip>
        <span slot-scope="{row}">
          <span v-if="row.rangeState===0">全体公司</span>
          <span v-if="row.rangeState===1" :title="row.scope.map(item=>item.companyName).join(',')">{{row.scope.map(item=>item.companyName).join(',')}}</span>
        </span>
      </el-table-column>
      <el-table-column align="center" prop="createDate" label="发布时间" />
      <el-table-column align="center" prop="realName" label="发布人" />
      <el-table-column align="center" prop="" label="推送状态">
        <template slot-scope="{row}">
          <el-tag size="mini" type="primary" v-if="row.isPush===0">未推送</el-tag>
          <el-tag size="mini" type="success" v-if="row.isPush===1">已推送</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250px">
        <template slot-scope="{row}">
          <el-button size="mini"  v-if="row.isPush===0" @click="push(row)">推送</el-button>
          <el-button size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:systemnotice:update')" @click="edit(row)">编辑</el-button>
          <el-button size="mini" icon="el-icon-delete" v-if="isAuth('platform-config:systemnotice:delete')" type="danger" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit="params.limit" :total="total" />
    <Form :show.sync="show" :id="id" @add="search" @edit="getList" />
  </jf-layout>
</template>

<script>
import Form from "./form";
export default {
  components: { Form },
  data() {
    return {
      show: false,
      params: {
        page: 1,
        limit: 10,
      },
      list: [],
      loading: false,
      id: '',
      total: 0,
      selectList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    push(row) {
      this.$confirm("确认推送？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(res => {
        this.$get('/platform-config/systemnotice/push', {
          id: row.id
        }).then(res => {
          this.$message.success('推送成功');
          this.getList();
        })
      }).catch(err => {

      })

    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      var res = await this.$get(
        "/platform-config/systemnotice/page",
        this.params
      );
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
    selectChange(list) {
      this.selectList = list;
    },
    add() {
      this.id = '';
      this.show = true;
    },
    edit(row) {
      this.id = row.id;
      this.show = true;
    },
    remove(row) {
      this.$confirm("确认删除选择的数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (row) {
            var ids = [row.id];
          } else {
            var ids = this.selectList.map((item) => {
              return item.id;
            });
            console.log(ids);
          }
          var res = await this.$del(
            "/platform-config/systemnotice/deleteByIds",
            ids
          );
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch(() => { });
    },
  },
};
</script>

